import { Platform } from '@app/classes/platform.class';
import { FeatureFlag } from '@app/enums';
import { Account } from '@app/models/account/account.model';
import { Address } from '@app/models/employee/address.model';
import { BenefitPlan } from '@models/benefits/benefit-plan.model';
import { BankAccount } from '@models/employee/bank-account.model';
import { Employee } from '@models/employee/employee.model';
import { Journey } from '@models/employee/journey.model';
import { Note } from '@models/employee/note.model';
import { Reminder } from '@models/employee/reminder.model';
import { Salary } from '@models/employee/salary.model';
import { Feedback } from '@models/performance/feedback.model';
import { Goal } from '@models/performance/goal.model';
import { Review } from '@models/performance/review.model';
import { Survey } from '@models/performance/survey.model';
import { Profile } from '@settings/interfaces/profile-permissions.interface';
import { ProfileTabs } from '../enums/profile-tabs.enum';

export const ProfilePermissions: Profile[] = [
    {
        tab: ProfileTabs.personal,
        title: 'settings.role-permissions.levels.personalInformation',
        segments: [
            {
                label: 'settings.role-permissions.levels.basicInformation',
                level: null,
                view: [...Employee.permission.view],
                edit: [...Employee.permission.edit],
            },
            {
                label: 'settings.role-permissions.levels.personalInformation',
                level: null,
                view: ['search.personalInformation', 'view.personalInformation'],
                edit: ['create.personalInformation', 'update.personalInformation', 'delete.personalInformation'],
            },
            {
                label: 'settings.role-permissions.levels.sin',
                level: null,
                view: [...Employee.sinPermission.some('view')],
                edit: [...Employee.sinPermission.some('update')],
                nest: true,
            },
            {
                label: 'settings.role-permissions.levels.birthdayEmployeeProfiles',
                level: null,
                view: [...Employee.bornOnPermission.some('view')],
                edit: [...Employee.bornOnPermission.some('update')],
                nest: true,
            },
            {
                label: 'settings.role-permissions.levels.birthdayEventsFeed',
                level: null,
                view: [...Employee.birthdayPermission.some('view')],
                edit: [...Employee.bornOnPermission.some('update')],
                nest: true,
            },
            {
                label: 'settings.role-permissions.levels.legalName',
                level: null,
                view: [...Account.legalNamePermission.some('view')],
                edit: [...Account.legalNamePermission.some('update')],
                nest: true,
            },
            {
                label: 'settings.role-permissions.levels.legalSex',
                level: null,
                view: [...Employee.legalSexPermission.some('view')],
                edit: [...Employee.legalSexPermission.some('update')],
                nest: true,
            },
            {
                label: 'settings.role-permissions.levels.address',
                level: null,
                view: [...Address.permission.view],
                edit: [...Address.permission.edit],
            },
            {
                label: 'settings.role-permissions.levels.emergencyContacts',
                level: null,
                view: ['search.employeeEmergencyContact', 'view.employeeEmergencyContact'],
                edit: [
                    'create.employeeEmergencyContact',
                    'update.employeeEmergencyContact',
                    'delete.employeeEmergencyContact',
                ],
            },
        ],
    },
    {
        tab: ProfileTabs.jobAndPay,
        title: 'settings.role-permissions.levels.jobAndPayInformation',
        segments: [
            {
                label: 'settings.role-permissions.levels.employmentInformation',
                level: null,
                view: [...Employee.permission.view, 'search.employeeEmploymentDetail', 'view.employeeEmploymentDetail'],
                edit: [
                    'create.employeeEmploymentDetail',
                    'update.employeeEmploymentDetail',
                    'delete.employeeEmploymentDetail',
                    ...Employee.permission.edit,
                ],
            },
            {
                label: 'settings.role-permissions.levels.compensation',
                level: null,
                view: [...Salary.permission.view],
                edit: [...Salary.permission.some('create')],
            },
            {
                label: 'settings.role-permissions.levels.bankAccount',
                level: null,
                view: [...BankAccount.permission.some('view')],
                edit: [...BankAccount.permission.edit],
            },
        ],
    },
    {
        tab: ProfileTabs.reminders,
        title: 'settings.role-permissions.levels.employeeReminders',
        segments: [
            {
                label: 'settings.role-permissions.levels.reminders',
                level: null,
                view: [...Reminder.permission.view],
                edit: [...Reminder.permission.edit],
            },
        ],
    },
    {
        tab: ProfileTabs.records,
        title: 'settings.role-permissions.levels.employeeRecords',
        segments: [
            {
                label: 'settings.role-permissions.levels.records',
                level: null,
                view: [...Note.permission.view],
                edit: [...Note.permission.edit],
            },
        ],
    },
    {
        tab: ProfileTabs.journey,
        title: 'settings.role-permissions.levels.employeeJourney',
        segments: [
            {
                label: 'settings.role-permissions.levels.journey',
                level: null,
                view: [...Journey.permission.view],
                edit: [...Journey.permission.edit],
            },
        ],
    },
    {
        tab: ProfileTabs.benefits,
        title: 'settings.role-permissions.levels.benefitsModule',
        segments: [
            {
                label: 'settings.role-permissions.levels.coverage',
                level: null,
                view: [...Platform.benefits.permission.view, ...BenefitPlan.permission.view],
                edit: [...Platform.benefits.permission.view, ...BenefitPlan.permission.edit],
            },
        ],
    },
    {
        tab: ProfileTabs.performance,
        title: 'settings.role-permissions.levels.performanceModule',
        segments: [
            {
                label: 'settings.role-permissions.levels.goals',
                level: null,
                view: [...Platform.performance.permission.view, ...Goal.permission.view],
                edit: [...Platform.performance.permission.view, ...Goal.permission.edit],
            },
            {
                label: 'settings.role-permissions.levels.reviews',
                level: null,
                view: [...Platform.performance.permission.view, ...Review.permission.view],
                edit: [...Platform.performance.permission.view, ...Review.permission.edit],
            },
            {
                label: 'settings.role-permissions.levels.feedback',
                level: null,
                view: [...Platform.performance.permission.view, ...Feedback.permission.view],
                edit: [...Platform.performance.permission.view, ...Feedback.permission.edit],
            },
            {
                label: 'settings.role-permissions.levels.surveys',
                level: null,
                view: [...Platform.performance.permission.view, ...Survey.permission.view],
                edit: [...Platform.performance.permission.view, ...Survey.permission.edit],
                hideIfHasFeatureFlag: FeatureFlag.performanceModuleSplit,
            },
        ],
    },
    {
        tab: ProfileTabs.survey,
        title: 'settings.role-permissions.levels.surveyModule',
        showIfHasFeatureFlag: FeatureFlag.performanceModuleSplit,
        segments: [
            {
                label: 'settings.role-permissions.levels.surveys',
                level: null,
                view: [...Survey.permission.view],
                edit: [...Survey.permission.edit],
                showIfHasFeatureFlag: FeatureFlag.performanceModuleSplit,
            },
        ],
    },
];
